/* eslint-disable no-nested-ternary */
import { html, css, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
import { initConfig, getRoutes } from '@kisters/wcp-base/app';
import { LoaderMixin } from '@kisters/wcp-base/common';
import { init } from '@kisters/wiski-web/api';
import { SM, ViewPort } from '@kisters/wcp-base/decorators';

// Import common components here
import '@kisters/ww-scc-common/views/index';
// import "@kisters/ww-scc-common/components/ww-sample-component"

import importActions from './import-actions/import-actions';
import 'pwa-helper-components/pwa-dark-mode.js';
import 'pwa-helper-components/pwa-install-button.js';
import 'pwa-helper-components/pwa-update-available.js';

import './components/ww-map-popup-scc';

// TODO use lib without compile might be better.
// can consider all ki-wcp project with build version instead import source and recompile it

@customElement('ww-app-index')
export default class wwAppIndex extends LoaderMixin(LitElement) {
  static styles = css`
    :host {
      height: 100%;
      width: 100%;
    }
  `;

  constructor() {
    super();
    this.configLoaded = false;
    this.configLocation = './config/app.json';
  }

  render() {
    if (!this.configLoaded) {
      return this._renderLoader();
    }
    // language=html
    return html` <ki-app-index .route="${getRoutes()}"></ki-app-index> `;
  }

  firstUpdated() {
    this.promiseLoader(
      initConfig(this.configLocation, importActions).then(config => {
        init({ dataPath: config.apiPath });
        this._config = config;
        this.configLoaded = true;
      }),
    );
  }
}
