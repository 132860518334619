import WwMapPopup from '@kisters/wiski-web/components/common/ww-map-popup';
import { html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { groupBy } from 'lodash-es';

const levelMappings = {};

@customElement('ww-map-popup-scc')
export default class WwMapPopupScc extends WwMapPopup {
  constructor() {
    super();
    this._plotLines = [];
    const formatter = new Intl.NumberFormat(this.i18n.language, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 4,
    });
    this.valFormatter = {
      format(val) {
        return val === 0 ? val : formatter.format(val);
      },
    };
  }

  _addPlotLine(shortName, val) {
    const yAxis = this.chart.get('mainYAxis');
    const plotLine = levelMappings[shortName];
    if (plotLine) {
      plotLine.value = val;
      plotLine.id = shortName;
      yAxis.addPlotLine(plotLine);
      this._plotLines.push(shortName);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  /*
  addAlarms(alarms, data) {
    if (alarms && data.length) {
      const thresholds = sortBy(
        alarms.filter(item => item.data[0]),
        item => item.data[0][1],
      ).map(item => ({ shortName: item.ts_shortname, val: item.data[0] }));
      const maxVal = maxBy(data, item => item[1]);
      const nextMax =
        thresholds.find(level => level.val[1] > maxVal[1]) || thresholds[0];
      const maxDate = new Date(maxVal[0]).getTime();

      if (nextMax) {
        this._addPlotLine(nextMax.shortName, nextMax.val[1], maxDate);
        this.chart
          .get('linehelper')
          .setData([[new Date(maxVal[0]).getTime(), nextMax.val[1]]], false);
        thresholds
          .filter(level => level.val[1] < maxVal[1])
          .forEach(item => this._addPlotLine(item.shortName, item.val[1]));
      }
    }
  } */

  async _setChartData(station) {
    if (this.chart && this.lastStationId !== station.station_id) {
      const yAxis = this.chart.get('mainYAxis');
      this.chart.get('linehelper').setData([], false);

      this.chart.showLoading();
      this._plotLines.forEach(line => yAxis.removePlotLine(line));
      this._plotLines = [];
      const series = this.chart.get('preview');
      series.setData([]);
      this.lastStationId = station.station_id;
      const site_no = station.site_no || station.ts_path?.split('/')[0];
      const stationparameter_no =
        station.stationparameter_no || station.ts_path?.split('/')[2];
      const file = 'complete.json';
      const data = await this.api.getTsData(
        `internet/stations/${site_no}/${station.station_no}/${stationparameter_no}/${file}`,
        false,
      );

      const sdata = this.getSeries(data, station.ts_shortname).data;

      if (stationparameter_no === 'Precip') {
        this.chart.yAxis[0].update({ softMax: 5 }, false);
        series.update({ type: 'bar', data: sdata });
      } else {
        this.chart.yAxis[0].update({ softMax: null }, false);
        series.update({ type: 'line', data: sdata });
      }

      this.chart.hideLoading();
    }
  }

  render() {
    if (this.stations && this.stations[0] && this.stations[0].mainParameter)
      return this._render();
    return this._renderLegacy();
  }

  _renderLegacy() {
    return html`${this.stations &&
      this.stations[0] &&
      this.stations[0].ts_values &&
      Object.keys(this.stations[0].ts_values).length > 1
        ? this._valueTmpl()
        : Object.values(groupBy(this.stations, 'station_id')).map(station =>
            this._stationTmpl(station),
          )}
      <div
        id="chartcontainer"
        style="display:${this.stations?.length === 1 &&
        Object.keys(this.stations[0].ts_values).length === 1 &&
        !this.stations[0].external
          ? 'block'
          : 'none'}"
      ></div>`;
  }
}
