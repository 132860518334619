/* eslint-disable max-classes-per-file */
import { filter, pickBy, every } from 'lodash-es';

// eslint-disable-next-line import/no-extraneous-dependencies
import {
  ViewPort,
  SM,
} from '@kisters/wcp-base/decorators';
import {
  Mix,
} from '@kisters/wcp-base/common';


import { html } from 'lit';
import { customElement } from 'lit/decorators.js';
import {WwpOverview} from '@kisters/wiski-web/views';

@customElement('wwp-overview-scc')
export default class WwpOverviewScc extends Mix(WwpOverview) {
  get filteredStations() {
    const regex = new RegExp(
      `${
        this.strictSearch
          ? `^${this.searchContent.replaceAll('*', '.')}`
          : this.searchContent.replaceAll('*', '.')
      }`,
      'i',
    );
    let filterOptions = JSON.parse(this.filterContent);

    if (filterOptions && this._dataLayer.config.filter) {
      filterOptions = pickBy(filterOptions, (value, key) =>
        this._dataLayer.config.filter.some(
          f => f.field === key || key === 'fav',
        ),
      );
    }

    let searchData = this._dataLayer.data?.filter(
      s =>
        regex.test(s.station_longname || s.station_name) ||
        regex.test(s.station_no),
    );
    const {exclude} = this._dataLayer.config.filter[0];
    if(exclude) {
      searchData = searchData.filter(
        s => !exclude.values.includes(s[exclude.field])
      );
    }
    if (filterOptions.fav) {
      searchData = searchData.filter(s =>
        this.__favouriteStationIds.includes(s.station_id),
      );
    }
    delete filterOptions.fav;
    return filter(searchData, obj =>
      every(filterOptions, (value, key) => {
        const val = obj[key] && obj[key].toString();
        return Array.isArray(value) ? value.includes(val) : val === value;
      }),
    ).filter(s => {
      const tag = s.__tag;
      if (tag && this._legends.length > 0) {
        return tag.split('|').every(t => this._legends.indexOf(t) !== -1);
      }
      return true;
    });
  }

}
